import {BsBoxSeam} from 'react-icons/bs';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {layout} from '@/sanity/schemas/primitives/layout';

export const productListSelection = () =>
	defineField({
		name: 'productListSelection',
		title: 'Produktliste, utvalg',
		type: 'object',
		icon: BsBoxSeam,
		fields: [title(), tagline(), products(), layout()],
		preview: {
			select: {
				title: 'title',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Produktliste, utvalg',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Overskrift',
		description: 'Overskrift for denne innholdsblokken.',
		type: 'string',
		group: 'content',
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Tekst',
		description: 'En valgfri tekst som står til overskriften.',
		type: 'string',
		group: 'content',
	});

const products = () =>
	defineField({
		name: 'products',
		title: 'produkter',
		description: 'Produktene som skal vises i produktlisten.',
		type: 'array',
		group: 'content',
		of: [
			{
				type: 'reference',
				options: {
					filter: () => {
						// Filter out all documents that is archived
						return {
							filter: '!isArchived',
						};
					},
				},
				to: [{type: 'product'}],
			},
		],
		validation: (Rule: Rule) => Rule.required(),
	});

export type ProductListSelectionSchema = Output<typeof productListSelection>;
