import {BsBoxSeam} from 'react-icons/bs';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField, defineType} from 'sanity-typed-schema';

import {
	BULLET,
	CALL_TO_ACTION,
	EMPHASIS,
	H2,
	H3,
	H4,
	H5,
	H6,
	IMAGE,
	LINK,
	NUMBERED,
	portableText,
	STRONG,
	UNDERLINE,
} from '@/sanity/components/portable-text/schema';
import {ALT_TEXT, imageWeb} from '@/sanity/schemas/primitives/image-web';
import {meta} from '@/sanity/schemas/primitives/meta';
import {PRODUCTS_PAGE_PATH} from '@/shared/constants';

export const product = () =>
	defineType({
		name: 'product',
		type: 'document',
		title: 'Produkt',
		icon: BsBoxSeam,
		fields: [
			isArchived(),
			title(),
			slug(),
			price(),
			priceId(),
			heading(),
			description(),
			image(),
			content(),
			meta(),
		],
		preview: {
			select: {
				title: 'title',
				slug: 'slug',
				isArchived: 'isArchived',
				image: 'image',
			},
			prepare: (select) => {
				const subtitle = select.isArchived
					? 'Arkivert'
					: `${PRODUCTS_PAGE_PATH}/` + (select.slug?.current ?? '...');
				return {
					title: select.title,
					subtitle,
					media: select.image ? select.image : BsBoxSeam,
				};
			},
		},
	});

const title = () =>
	defineField({
		name: 'title',
		type: 'string',
		title: 'Tittel',
		description:
			'Tittelen vises i nettleserens vidustittel, menyer og listevisninger og bør være kort og konsis. Merk at tittelen er forskjellig fra overskriften, som redigeres lenger ned.',
		validation: (Rule: Rule) => Rule.required(),
	});

const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		description: 'En kort introduduksjon til dette produktet.',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
	});

const slug = () =>
	defineField({
		name: 'slug',
		type: 'slug',
		title: 'Slug',
		description:
			'Siste del av URLen til dette produktet på nettstedet. Slug bør formatteres med små bokstaver og uten spesialtegn. Det enkleste er å generere denne basert på produktets tittel.',
		options: {
			source: 'title',
		},
		validation: (Rule: Rule) =>
			Rule.custom((param?: {current: string}) => {
				if (!param) {
					return 'Obligatorisk';
				}
				if (param.current?.includes(' ') || param.current?.includes('/')) {
					return 'Slug kan ikke inneholde mellomrom eller skråstreker';
				}
				return true;
			}),
	});

const description = () =>
	defineField({
		...portableText({
			decorators: [STRONG, EMPHASIS],
			annotations: [],
			styles: [],
			lists: [],
			customTypes: [],
		}),
		name: 'description',
		title: 'Beskrivelse',
		description: 'En kort tekst som oppsummerer produktet på et avsnitt eller to.',
	});

const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT]}),
		description:
			'Et bilde som representerer produktet. Brukes gjerne sammen med beskrivelsen i listevisninger.',
	});

const content = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			styles: [H2, H3, H4, H5, H6],
			lists: [BULLET, NUMBERED],
			decorators: [STRONG, EMPHASIS, UNDERLINE],
			customTypes: [CALL_TO_ACTION, IMAGE],
		}),
		name: 'content',
		title: 'Brødtekst',
		description: 'En lengre tekst som utgjør hovedinnholdet for dette produktet.',
	});

const price = () =>
	defineField({
		name: 'price',
		type: 'number',
		title: 'Pris',
		description: 'Prisen på dette produktet.',
		validation: (Rule: Rule) => Rule.required(),
		readOnly: true,
	});

const priceId = () =>
	defineField({
		name: 'priceId',
		type: 'string',
		title: 'Stripe Price ID',
		description: 'Stripe Price ID for dette produktet.',
		validation: (Rule: Rule) => Rule.required(),
		readOnly: true,
	});

const isArchived = () =>
	defineField({
		name: 'isArchived',
		type: 'boolean',
		title: 'Arkivert',
		description: 'Om dette produktet er arkivert eller ikke.',
		validation: (Rule: Rule) => Rule.required(),
		initialValue: false,
		readOnly: true,
	});

export type ProductSchema = Output<typeof product>;
